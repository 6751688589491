main {
  min-height: 80vh;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h1 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
h3 {
  padding: 1rem 0;
}
.rating span {
  margin: 0.1rem;
}

.header-logo {
  width: 2.5rem;
  height: 2rem;
}
/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
  height: 40vh;
}

.bookDetails.carousel-item-next,
.bookDetails.carousel-item-prev,
.bookDetails.carousel-item.active {
  display: flex;
  height: 60vh !important;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h4 {
  color: #fff;
}
.bookCard {
  text-align: center;
}
.bookCard .bookImage {
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  min-height: 16rem;
  max-height: 16rem;
}
.carousel img {
  display: block;
  max-width: 100%;
  max-height: 100%;

  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}

.product-image-top-right {
  position: absolute;
  top: 2%;
  right: 8%;
  z-index: 1;
}
.product-image-center {
  position: absolute;
  top: 25%;
  left: 25%;
}

.text-fast-red {
  color: brown;
}
.font-size-medium {
  font-size: medium;
}
.bookDetailImage {
  max-height: 24rem;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px; 
}
.mobileView{
  display: none;
}
.desktopView {
  display: block;
}

@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}
@media only screen and (max-width: 768px) {
  .bookCard .bookImage {
    min-height: 13rem;
    max-height: 13rem;
  }
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: flex;
    height: 25vh;
  }
}

@media only screen and (max-width: 600px) {
  .mobileView {
    display: block;
  }
  .desktopView {
    display: none;
  }
}
